import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/button";
import "../../../styles/pages/postulationState.scss";
import { FileTypeId, generalStatesPostulation } from "../../../constants/enum";
import {
  callApi,
  saveInformationFligth,
  getInformationFligth,
} from "../../../services/apiService";
import { FormProvider, useForm } from "react-hook-form";
import MainContainer from "../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import { getUserInfo } from "../../../services/authService";

const UploadInformationFlight = ({
  generalState,
  openInformationFligthModal,
  closeHandleInformationFligthModal,
  statePostulationModal,
  postulationId,
  setGeneralState
}) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const [dateInterview, setDateInterview] = useState(null);
  const [interviewsId, setInterviewsId] = useState(0);

  useEffect(() => {
    setBotonActivo(false);
    if (!openInformationFligthModal) {
      setDateInterview(null);
      return;
    }
    if (
      postulationId == null ||
      postulationId == 0 ||
      !openInformationFligthModal
    ) {
      return;
    }
    const dataToGet = {
      postulationId: parseInt(postulationId),
    };
    callApi(
      () => getInformationFligth(dataToGet),
      (data) => {
        loadData(data);
      }
    );
  }, [openInformationFligthModal]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const validations = {
    lodging: {
      required: { value: true, message: t("healthInfo.required") },
    },
  };

  const handleClose = () => {
    setValue("lodging", "");
    setBotonActivo(false);
    reset();
    closeHandleInformationFligthModal();
  };

  const onSubmit = handleSubmit((data) => {
    var userInfo = getUserInfo();
    var dataToSave = new FormData();
    dataToSave.append("userId", parseInt(userInfo.id));
    dataToSave.append("postulationId", postulationId);
    dataToSave.append("image", data.image[0]);
    dataToSave.append("file_type_id", parseInt(FileTypeId.Flight));
    dataToSave.append("lodging", data.lodging);
    dataToSave.append("id", 0);

    callApi(
      () => saveInformationFligth(dataToSave),
      () => {
        NotificationManager.success(t("form.success"));
        setBotonActivo(false);
        setGeneralState(generalStatesPostulation.flightAndAccommodation);
        handleClose();
      }
    );
  });

  const loadData = (obj) => {
    setValue("lodging", !obj || !obj.lodging ? "" : obj.lodging);
  };

  useEffect(() => {
    setBotonActivo(false);
  }, []);

  return (
    <>
      <Modal
        open={openInformationFligthModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <h1 className="title">
            {t("postulationState.flightAndAccommodation")}
          </h1>
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <div className="mb-3">
                <label>{t("postulation.direction")}</label>
                <input
                  placeholder={t("register.direction")}
                  className="form-control mb-2"
                  name="lodging"
                  {...register("lodging", validations.lodging)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.lodging && errors.lodging.message}
                </span>
              </div>
              <div className="mb-3">
                <label>{t("postulation.flightReservation")}</label>
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => handleClose()}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default UploadInformationFlight;
