import http from "./httpService";
import { NotificationManager } from "react-notifications";
import i18next from "../i18n";

const urlApi = process.env.REACT_APP_API_URL;

const fnError = (err, handleError) => {
  var error = "";
  if (err.response) {
    console.log("TEST ERROR", err);
    console.log("TEST H ERROR", handleError);

    if (err.response.data && err.response.status === 400) {
      handleError(err);
      return;
    } else if (err.response.data) {
      error = err.response.data.error;
      console.log("error-response: ", err.response.data.error);
    } else {
      error = err.response;
      console.log("error-response: ", err.response);
    }
    //console.log("err.response",err.response)
  } else if (err.request) {
    error = err.request;
    console.log("error-request: ", err.request);
  } else {
    error = err;
    console.log("error-error: ", err);
  }

  if (handleError) {
    console.log("handleError: -> ", error);
    handleError(error);
  }
};

export const callActionApi = (apiFunc, setData, handleError, setLoader) => {
  const fetchData = async () => {
    try {
      await apiFunc()
        .then((response) => {
          if (response) {
            setData(response.data);
          }
        })
        .catch((err) => fnError(err, handleError))
        .finally(() => {
          if (setLoader) setLoader(false);
        });
    } catch (e) {
      console.log("error: ", e.message);
    }
  };
  if (setLoader) setLoader(true);
  fetchData();
};

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => fnError(err, handleError))
      .finally(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApi = (apiFunc, setData, setLoader) => {
  callActionApi(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string" && error) {
        NotificationManager.error(i18next.t(error));
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        var d = error.response.data;
        console.log("error", d.error ? d.error : d);
        if (error.response.data.error) {
          console.log(i18next, error.response.data.error);
          NotificationManager.warning(i18next.t(error.response.data.error));
        } else NotificationManager.warning(i18next.t(error.response.data));
      }
    },
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const callApiAsync = async (apiFunc, setData, setLoader) => {
  await callActionApiAsync(
    apiFunc,
    setData,
    (error) => {
      if (typeof error === "string") {
        NotificationManager.error(error);
      } else if (
        error.response &&
        error.response.data &&
        error.response.status === 400
      ) {
        NotificationManager.warning(error.response.data);
      }
    },
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

const loadFile = (url, formData) =>
  http.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getUsers = () => http.get(`${urlApi}/users`);

export const getToken = (data) => http.post(`${urlApi}/login`, data);

export const newUser = (data) => http.post(`${urlApi}/user/new`, data);

export const confirmEmail = (data) =>
  http.post(`${urlApi}/confirm-password`, data);

export const getUserInfoBd = (data) => http.post(`${urlApi}/user-info`, data);

export const editUserInfo = (data) =>
  http.post(`${urlApi}/user-info/edit`, data);

export const changePassword = (data) =>
  http.post(`${urlApi}/user-info/change-password`, data);

export const recoverPassword = (data) =>
  http.post(`${urlApi}/recover-password`, data);

export const getCodeIsValid = (code) =>
  http.post(`${urlApi}/CodeIsValid`, code);

export const recoverPasswordNew = (data) =>
  http.post(`${urlApi}/recover-password/new`, data);

export const uploadImages = (formData) =>
  loadFile(`${urlApi}/upload/img`, formData);

export const updateFormPostulation = (data) =>
  http.post(`${urlApi}/gallery/updateFormPostulation`, data);
  
export const getImages = (data) =>
  http.post(`${urlApi}/gallery/getImages`, data);
    
export const getConditions = (data) =>
http.post(`${urlApi}/gallery/getConditions`, data);

export const getFilesNoConditions = (data) =>
http.post(`${urlApi}/gallery/getFilesNoConditions`, data);

export const uploadTemplate = (formData) =>
  loadFile(`${urlApi}/upload/template`, formData);

export const getTemplates = (data) =>
  http.post(`${urlApi}/gallery/getTemplates`, data);

export const deleteImage = (data) =>
  http.post(`${urlApi}/gallery/deleteImage`, data);

export const getEmployers = (data) =>
  http.post(`${urlApi}/employees/list`, data);

export const saveEmployer = (data) =>
  http.post(`${urlApi}/employees/saveEmployer`, data);

export const savePositionEmployer = (data) =>
  http.post(`${urlApi}/employees/savePositionEmployer`, data);

export const getEmployer = (data) =>
  http.post(`${urlApi}/employees/getEmployer`, data);

export const getPosition = (data) =>
  http.post(`${urlApi}/employees/getPosition`, data);

export const getPositionsByEmployer = (data) =>
  http.post(`${urlApi}/employees/getPositionsByEmployer`, data);

export const deletePosition = (data) =>
  http.post(`${urlApi}/employees/deletePosition`, data);
  
export const deleteEmployees = (data) =>
  http.post(`${urlApi}/employees/delete`, data);

export const editEmployees = (data) =>
  http.post(`${urlApi}/employees/edit`, data);

export const saveEmployerFile = (formData) =>
  loadFile(`${urlApi}/upload/employerFile`, formData);

export const getEmployerFiles = (data) =>
  http.post(`${urlApi}/gallery/getEmployerFiles`, data);

export const deleteEmployerFile = (data) =>
  http.post(`${urlApi}/gallery/deleteEmployerFile`, data);
  
export const getCourse = (data) => http.post(`${urlApi}/courses/list`, data);

export const getCourseplanning = (data) =>
  http.post(`${urlApi}/courses/list/planning`, data);

export const addCourse = (data) => http.post(`${urlApi}/courses/add`, data);

export const deleteCourse = (data) =>
  http.post(`${urlApi}/courses/delete`, data);

export const editCourse = (data) => http.post(`${urlApi}/courses/edit`, data);

export const getCountries = () => http.get(`${urlApi}/countries`);

export const saveTravelerPassport = (data) =>
  http.post(`${urlApi}/registrationForm/travelerPassport`, data);

export const saveTravelerContact = (data) =>
  http.post(`${urlApi}/registrationForm/contactDetails`, data);

export const loadContactInfo = (data) =>
  http.post(`${urlApi}/registrationForm/loadContactInfo`, data);

export const getTravelerPassport = (data) =>
  http.post(`${urlApi}/registrationForm/getTravelerPassport`, data);

export const setFormState = (data) =>
  http.post(`${urlApi}/registrationForm/setFormState`, data);

export const getPostulationInfo = (data) =>
  http.post(`${urlApi}/registrationForm/getFormState`, data);

export const saveTravelerFormation = (data) =>
  http.post(`${urlApi}/registrationForm/saveTravelerFormation`, data);

export const getTravelerFormation = (data) =>
  http.post(`${urlApi}/registrationForm/getTravelerFormation`, data);

export const getLanguages = (data) =>
  http.post(`${urlApi}/languages/getlanguages`, data);

export const postLanguages = (data) =>
  http.post(`${urlApi}/languages/postlanguage`, data);

export const deleteLanguages = (data) =>
  http.post(`${urlApi}/languages/delete`, data);

export const editLanguages = (data) =>
  http.post(`${urlApi}/languages/edit`, data);

export const postHealthData = (data) =>
  http.post(`${urlApi}/healthData/post`, data);

export const postulationInfo = (data) =>
  http.post(`${urlApi}/postulation/get`, data);

export const newPostulation = (data) =>
  http.post(`${urlApi}/postulation/new`, data);

export const getHealthData = (data) =>
  http.post(`${urlApi}/healthData/get`, data);

export const saveTravelerVisa = (data) =>
  http.post(`${urlApi}/registrationForm/saveTravelerVisa`, data);

export const getTravelerVisa = (data) =>
  http.post(`${urlApi}/registrationForm/getTravelerVisa`, data);

export const saveTravelerRelative = (data) =>
  http.post(`${urlApi}/registrationForm/saveTravelerRelative`, data);

export const getTravelerRelatives = (data) =>
  http.post(`${urlApi}/registrationForm/getTravelerRelatives`, data);

export const deleteRelative = (data) =>
  http.post(`${urlApi}/registrationForm/deleteRelative`, data);

export const editRelative = (data) =>
  http.post(`${urlApi}/registrationForm/editRelative`, data);

export const saveAdditionalInfo = (data) =>
  http.post(`${urlApi}/registrationForm/saveAdditionalInfo`, data);

export const getAdditionalInfo = (data) =>
  http.post(`${urlApi}/registrationForm/getAdditionalInfo`, data);

export const saveBilling = (data) =>
  http.post(`${urlApi}/registrationForm/saveBilling`, data);

export const setWorkDates = (data) =>
  http.post(`${urlApi}/postulation/setWorkDates`, data);

export const setOfficeUse = (data) =>
  http.post(`${urlApi}/postulation/setOfficeUse`, data);

export const getBilling = (data) =>
  http.post(`${urlApi}/registrationForm/getBilling`, data);

export const getAlertNotification = (data) =>
  http.post(`${urlApi}/notification/getAlert`, data);

export const sendNotification = (data) =>
  http.post(`${urlApi}/notification/sendNotification`, data);

export const sendNotificationAgency = (data) =>
  http.post(`${urlApi}/notification/sendNotificationAgency`, data);

export const sendNotificationB = (data) =>
  http.post(`${urlApi}/notification/sendNotificationB`, data);

export const pdfGenerator = (data) =>
  http.post(`${urlApi}/formFinalStep/pdfGenerator`, data);

export const pdfGeneratorSigned = (data) =>
  http.post(`${urlApi}/formFinalStep/pdfGeneratorSigned`, data);

export const signDocument = (data) =>
  http.post(`${urlApi}/formFinalStep/signDocument`, data);

  export const signConditions = (data) =>
  http.post(`${urlApi}/conditionsPdf/signConditions`, data);

export const saveAnswerPostulation = (data) =>
  http.post(`${urlApi}/postulation/saveAnswerPostulation`, data);

export const saveScheduleInterview = (data) =>
  http.post(`${urlApi}/postulation/saveScheduleInterview`, data);
  
export const saveCommentsInterview = (data) =>
  http.post(`${urlApi}/postulation/saveCommentsInterview`, data);
  
export const saveAnswerInterview = (data) =>
  http.post(`${urlApi}/postulation/saveAnswerInterview`, data);
  
export const saveVideoUrl = (data) =>
  http.post(`${urlApi}/postulation/saveVideoUrl`, data);

export const getVideoUrl = (data) =>
  http.post(`${urlApi}/getUserVideo`, data);

export const setStateVideo = (data) =>
http.post(`${urlApi}/postulation/setStateVideo`, data);

export const getWorkDatesInfo = (data) =>
  http.post(`${urlApi}/postulation/getWorkDatesInfo`, data);

export const getOfficeUseInfo = (data) =>
  http.post(`${urlApi}/postulation/getOfficeUseInfo`, data);

export const changeState = (data) =>
  http.post(`${urlApi}/postulation/changeState`, data);

export const saveProcessPostulation = (data) =>
  http.post(`${urlApi}/postulation/saveProcessPostulation`, data);

export const getMessagesPostulation = (data) =>
  http.post(`${urlApi}/postulation/getMessagesPostulation`, data);

export const saveChangeProgram = (data) =>
  http.post(`${urlApi}/postulation/saveChangeProgram`, data);

export const declinePostulation = (data) =>
  http.post(`${urlApi}/postulation/declinePostulation`, data);

  export const declineInformed = (data) =>
  http.post(`${urlApi}/postulation/declineInformed`, data);

  export const getDateInterview = (data) =>
  http.post(`${urlApi}/postulation/getDateInterview`, data);

  export const saveDateInterview = (data) =>
  http.post(`${urlApi}/postulation/saveDateInterview`, data);

  export const saveInformationFligth = (formData) =>
  loadFile(`${urlApi}/upload/saveInformationFligth`, formData);

  export const getInformationFligth = (data) =>
  http.post(`${urlApi}/postulation/getInformationFligth`, data);

  export const saveInterviewWwce = (data) =>
  http.post(`${urlApi}/postulation/saveInterviewWwce`, data);
  
  export const saveMockInterview = (data) =>
    http.post(`${urlApi}/postulation/saveMockInterview`, data);

  export const getWorkPreferencesTable = () =>
  http.post(`${urlApi}/workPreferences/getTableData`);

  export const submitWorkPreferencesData = (data) =>
  http.post(`${urlApi}/workPreferences/submitWorkPreferencesData`, data);

  export const getWorkPreferencesByPostulation = (data) =>
  http.post(`${urlApi}/workPreferences/getWorkPreferencesByPostulation`, data);

  export const getWorkPreferencesDates = (data) =>
    http.post(`${urlApi}/workPreferences/getWorkPreferencesDates`, data);

  export const getVideoAndCv = (data) =>
  http.post(`${urlApi}/postulation/getVideoAndCv`, data);
 
  export const saveCvAndVideoState = (data) =>
  http.post(`${urlApi}/postulation/saveCvAndVideoState`, data);

  export const saveCvAndVideo = (formData) =>
  loadFile(`${urlApi}/upload/saveCvAndVideo`, formData);

  export const saveVideo = (data) =>
  http.post(`${urlApi}/cv/saveVideo`, data);

export const saveCvEducation = (data) =>
  http.post(`${urlApi}/cv/saveCvEducation`, data);

export const saveCvExperience = (data) =>
  http.post(`${urlApi}/cv/saveCvExperience`, data);

export const getCv = (data) =>
  http.post(`${urlApi}/cv/getCv`, data);

  export const saveCv = (data) =>
  http.post(`${urlApi}/cv/saveCv`, data);

  export const getCvPdf = (data) =>
  http.post(`${urlApi}/cv/getCvPdf`, data);

  export const deleteCvEducationExperience = (data) =>
  http.post(`${urlApi}/cv/deleteCvEducationExperience`, data);

export const getReportSelectedEmployers  = (data) =>
  http.post(`${urlApi}/reports/getReportSelectedEmployers`, data);

export const getReportActiveEmployers  = (data) =>
  http.post(`${urlApi}/reports/getReportActiveEmployers`, data);

export const savePostulationOrder  = (data) =>
  http.post(`${urlApi}/postulation/savePostulationOrder`, data);
