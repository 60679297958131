import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/button";
import "../../../styles/pages/postulationState.scss";
import {
  generalStatesPostulation,
} from "../../../constants/enum";
import {
  callApi,
  getDateInterview,
  saveDateInterview,
} from "../../../services/apiService";
import { FormProvider, useForm } from "react-hook-form";
import MainContainer from "../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import DateInput from "../../../components/forms/inputs/dateInput";

const InterviewModal = ({
  generalState,
  openInterviewsModal,
  closeHandleInterviewsModal,
  statePostulationModal,
  postulationId,
  setGeneralState
}) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const [dateInterview, setDateInterview] = useState(null);
  const [interviewsId, setInterviewsId] = useState(0);

  useEffect(() => {
    if( !openInterviewsModal){
      setDateInterview(null);
      return;
    }
    if(postulationId == null || postulationId == 0 || !openInterviewsModal ){
        return;
    }
    const dataToGet = {
      postulationId: parseInt(postulationId),
      statePostulationModal: statePostulationModal,
    };
    callApi(
      () => getDateInterview(dataToGet),
      (data) => {
        setInterviewsId(data?data.id:0);
        if (statePostulationModal == generalStatesPostulation.interviewWork) {
          setDateInterview(data.interviewJobDate);
        } else {
          setDateInterview(data.interviewConsularDate);
        }
      }
    );
  }, [openInterviewsModal]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const validations = {
    dateInterview: {
      required: { value: true, message: t("validationError.city.require") },
    },
  };

  const handleClose = () => {
    setValue("dateInterview","");
    reset();
    closeHandleInterviewsModal();
  };

  const onSubmit = handleSubmit((data) => {
    setBotonActivo(true);
    const dataToSave = {
      postulationId: parseInt(postulationId),
      dateInterview : data.dateInterview,
      statePostulationModal: statePostulationModal,
      interviewsId : parseInt(interviewsId),
    }
    callApi(
      () => saveDateInterview(dataToSave),
      () => {
        NotificationManager.success(t("form.success"));
        if(statePostulationModal == generalStatesPostulation.interviewConsular){
          setGeneralState(generalStatesPostulation.interviewConsular);
        }
        if(statePostulationModal == generalStatesPostulation.interviewWork){
          setGeneralState(generalStatesPostulation.interviewWork);
        }
        handleClose();
      }
    );
  });

  const getTitle = (x) => {
    switch (x) {
      case generalStatesPostulation.interviewWork:
        return "general.interviewWork";
      case generalStatesPostulation.interviewConsular:
        return "general.interviewConsular";
      default:
        return "";
    }
  };

  useEffect(() => {
    setValue("dateInterview", !dateInterview || !dateInterview ? "" : new Date(dateInterview));
  },[dateInterview]);

  return (
    <>
      <Modal
        open={openInterviewsModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer showImage={false} class="ww-container-m">
          <h1 className="title">{t(getTitle(statePostulationModal))}</h1>
          <FormProvider {...methods}>
            <form>
              <div className="inputs-style">
                <DateInput
                  name="dateInterview"
                  control={control}
                  placeholder={t("postulation.interviewDate")}
                  label={
                    <label>
                      {t("postulation.interviewDate")}
                      <span className="text-danger"> *</span>
                    </label>
                  }
                  {...register("dateInterview", validations.dateInterview)}
                />
                <span className="text-danger text-small d-block mb-2">
                  {errors.dateInterview && errors.dateInterview.message}
                </span>
              </div>
              <div className="btn-container">
                <Button
                  type="ww-btn-secondary"
                  size="btn-sx"
                  label={t("main.close")}
                  onClick={() => {
                    handleClose();
                  }}
                />
                <Button
                  size="btn-sx"
                  label={t("main.getInto")}
                  onClick={onSubmit}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default InterviewModal;
